<template>
    <div class="label-list">
        <van-search v-model="searchValue" placeholder="搜索" shape="round" @input="onInput" class="fixed" />
        <div class="label-container" v-if="fliter_labels_list.length !== 0 || isLoading" v-loading="isLoading">
            <div v-for="(item, index) in labels_list" :key="index">
                <div class="label_group" v-if="item.showTagGroup">
                    <div class="label_title">{{ item.tag_group_name }}</div>
                    <div class="label_content">
                        <span
                            v-for="(label_item, label_index) in labels_list_filter(item.tags)"
                            :key="label_index"
                            class="label_item"
                            :class="tag_list.indexOf(label_item.tag_id) > -1 ? 'active' : ''"
                            @click="labelClick(label_item)"
                        >
                            {{ label_item.tag_name }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="button_list fixed">
                <button class="reset button" @click="reset">重置</button>
                <button class="confirm button" @click="confirm">确认({{ tag_list.length }})</button>
            </div>
        </div>
        <empty-page :msg="emptyMsg" v-else></empty-page>
    </div>
</template>
<script>
import emptyPage from '@/components/emptyPage.vue';
import Http from '@xiaoe/venus/dist/net/request';
export default {
    name: 'labelList',
    components: { emptyPage },
    data() {
        return {
            labels_list: [],
            tag_list: [],
            tag_name: [],
            isReset: false,
            isConfirm: false,
            isLoading: true,
            searchValue: '',
            fliter_labels_list: []
        };
    },
    created() {
        this.fetchLabelData();
        if (this.$store.state.tagList.length) {
            this.tag_list = this.$store.state.tagList;
            this.tag_name = this.$store.state.tagName;
        }
    },
    computed: {
        labels_list_filter() {
            return function (item) {
                return item.filter((e) => {
                    return e.isFilter;
                });
            };
        },
        emptyMsg() {
            return this.searchValue ? '搜索无结果' : '暂无标签';
        }
    },
    methods: {
        async fetchLabelData() {
            const headers = { 'X-XE-SOURCE': 'wework_self_build' };
            const url = '/forward_admin/wework/custom/tag_list';
            const params = { tag_type: 1 };
            await Http.request({
                url,
                method: 'get',
                params,
                headers
            })
                .then((res) => {
                    this.isLoading = false;
                    this.labels_list = res.data;
                    this.handleData();
                })
                .catch((err) => {
                    this.$toast(err.msg);
                });
        },
        handleData() {
            this.labels_list.forEach((item) => {
                item.showTagGroup = true;
                item.tags.forEach((s) => {
                    s.isFilter = true;
                    this.fliter_labels_list.push(s.tag_id);
                });
            });
        },
        labelClick(item) {
            const select_index = this.tag_list.indexOf(item.tag_id);
            if (select_index > -1) {
                this.tag_list.splice(select_index, 1);
                this.tag_name.splice(select_index, 1);
            } else {
                this.tag_list.push(item.tag_id);
                this.tag_name.push(item.tag_name);
            }
        },
        reset() {
            this.tag_list = [];
            this.tag_name = [];
        },
        confirm() {
            this.$store.commit('setTagList', this.tag_list);
            this.$store.commit('setTagName', this.tag_name);
            this.$router.go(-1);
        },
        onInput() {
            let copyList = this.labels_list;
            copyList.forEach((item) => {
                item.showTagGroup = false;
                item.tags.forEach((s) => {
                    if (s.tag_name.indexOf(this.searchValue) == -1) {
                        s.isFilter = false;
                        let index = this.fliter_labels_list.indexOf(s.tag_id);
                        if (index > -1) {
                            this.fliter_labels_list.splice(index, 1);
                        }
                    } else {
                        s.isFilter = true;
                        item.showTagGroup = true;
                        let index = this.fliter_labels_list.indexOf(s.tag_id);
                        if (index == -1) {
                            this.fliter_labels_list.push(s.tag_id);
                        }
                    }
                });
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.label-list {
    overflow: auto;
    height: 100%;
    .label-container {
        position: relative;
        height: 100%;
        padding: 1.28rem 0.24rem 1.36rem;
        background: #f5f5f5;
        overflow-y: auto;
        min-height: 2rem;
        .label_group {
            padding: 0.32rem;
            background: #ffffff;
            border-radius: 0.16rem;
            margin-top: 0.16rem;
            .label_title {
                height: 0.48rem;
                font-size: 0.32rem;
                font-weight: 600;
                color: #333333;
                line-height: 0.48rem;
            }
            .label_content {
                display: flex;
                flex-wrap: wrap;
                margin-left: -0.24rem;
                .label_item {
                    margin-top: 0.24rem;
                    margin-left: 0.24rem;
                    padding: 0.06rem 0.16rem;
                    background: #f5f5f5;
                    border-radius: 0.26rem;
                    font-size: 0.24rem;
                    font-weight: 400;
                    color: #333333;
                    line-height: 0.36rem;
                    border: 1px solid #f5f5f5;
                    &.active {
                        border: 1px solid #1472ff;
                        color: #105cfb;
                        background: #e7edfb;
                    }
                }
            }
        }
        .button_list {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff;
            height: 1.12rem;
            display: flex;
            justify-content: space-around;
            align-items: center;
            z-index: 9999;
            .button {
                width: 3.28rem;
                height: 0.8rem;
                background: #ffffff;
                border-radius: 0.4rem;
                border: 1px solid #cccccc;
            }
            .confirm {
                width: 3.28rem;
                height: 0.8rem;
                background: #1472ff;
                border-radius: 0.4rem;
                font-size: 0.32rem;
                font-weight: 500;
                color: #ffffff;
                line-height: 0.48rem;
            }
        }
    }
}
</style>
<style lang="scss">
.label-list {
    .van-search {
        position: fixed;
        top: 0;
        z-index: 9999;
    }
}
</style>
